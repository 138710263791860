<template>
  <v-container
    v-if="prealbaran"
    fluid
    grid-list-md
    class="view-prealbaran ajuste-layout"
  >

    <!-- Datos Prealbarán -->
    <div class="prealbaran-info" >
      <label> <b>CÓDIGO: </b>{{ prealbaran.codigo }}</label>
      <label> <b>FECHA: </b>{{ formatDate(prealbaran.fecha) }}</label>
      <label> <b>ESTADO: </b>{{ prealbaran.estado }}</label>
      <label> <b>TIPO ORDEN: </b>{{ prealbaran.tipo }}</label>

      <label v-if="prealbaran.codpresupuestocli"> <b>PRESUPUESTO: </b>SI ({{ prealbaran.codpresupuestocli }})</label>
      <label v-else> <b>PRESUPUESTO: </b>NO</label>

      <!-- <label> <b>OBSERVACIONES: </b>{{ prealbaran.observaciones }}</label> -->
    </div>

    <v-toolbar flat v-if="prealbaran.estado=='Pendiente' || dialog">
      <v-spacer>PREALBARAN</v-spacer>
      <v-dialog v-model="dialog" persistent max-width="500px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark class="mb-2" v-on="on" v-if="prealbaran.estado=='Pendiente'">{{
            $t("carro_dialog_titulo_btn_linea")
          }}</v-btn>
        </template>
        <v-card style="background:#ffffff;">
          <v-card-title>
            <span class="headline">{{ $t("carro_dialog_titulo_linea") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container v-if="dialog_view == 'search'">
                <v-select
                  :items="tipoarticulos"
                  item-value="id"
                  item-text="name"
                  label="Tipo de artículo"
                  v-model="codtipoinel_selected"
                  dense
                ></v-select>
              <v-text-field v-model="articulo_desc" label="Buscar por referencia o descripción..."></v-text-field>
              <v-list
                class="resultado_busqueda"
                v-if="results_articulo"
              >
                <v-list-item
                  v-for="item in results_articulo"
                  :key="item.r"
                  @click="selecionar_articulo(item)"
                >
                  <v-list-item-content>
                    <strong style="font-size:11px;">{{ item.r }}</strong>
                    <v-list-item-title v-html="item.d"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-container>
            <v-container v-if="dialog_view == 'insert'">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="prealbaranlinea.referencia"
                    label="Referencia"
                     disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    class="uppercase"
                    v-model="prealbaranlinea.descripcion"
                    label="Descripción"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="prealbaranlinea.cantidad"
                    align-left
                    type="number"
                    label="Cantidad"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#555555" text @click="closedialog()">Cancelar</v-btn>
            <v-btn color="#555555" text @click="dialog_view = 'search'" v-if="dialog_view == 'insert'">Buscar</v-btn>
            <v-btn color="#555555" text @click="actualizarlinea(prealbaranlinea)" v-if="dialog_view == 'insert'" >{{ $t("carro_dialog_titulo_btn_linea") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <div class="listado" v-if="prealbaran.lineas" >
      <v-data-table
        class="tabla_escritorio"
        :headers="headers"
        :items="prealbaran.lineas"
        hide-default-footer
         :items-per-page="1000"
        item-key="item.b"
      >
        <!-- Columna de seleccionables thead --start   -->
        <!-- <template v-slot:headers="props">
          
          <tr>
            <th class="text-xs-center">
              <div class="box-selectable">
                <div>
                  <v-checkbox :input-value="item.all" :indeterminate="item.indeterminate" primary></v-checkbox>
                </div>
                <div>
                  <v-icon @click="dialogConfirmRm=true" color="red">devare_sweep</v-icon>
                </div>
              </div>
            </th>
            <th
              v-for="header in item.headers"
              :key="header.text"
              :class="header.class"
            >{{ header.text }}</th>
          </tr>
        </template> -->
        <!-- Columna de seleccionables thead  -- end    -->

        <template v-slot:item="{ item }">
          <tr :key="item.idlinea">
            <td class="text-xs-left col-descripcion">{{ item.referencia }}</td>
            <td class="text-xs-left col-descripcion">{{ item.descripcion }}</td>
            <td class="text-center col-descripcion">{{ item.cantinicial }}</td>
            <!-- <td class="text-xs-left">{{ item.pvp | formatPrice | currency }}</td> -->
            <!-- <td class="text-xs-left">{{ item.pvp }}</td> -->

            <td class="text-center justify-center">
              <div class="box-cantidad" style="margin:auto;">
                <v-icon @click="btnRestCant(item)" style="border-right:none;" v-if="prealbaran.estado=='Pendiente'">remove</v-icon>
                <input v-if="prealbaran.estado=='Pendiente'"
                  v-model="item.cantidad"
                  @change="actualizarlinea(item)" min="0" step="0.5" type="number" value=""
                />
                <v-icon @click="btnSumCant(item)" style="border-left:none;" v-if="prealbaran.estado=='Pendiente'">add</v-icon>
                <label v-if="prealbaran.estado=='Confirmado'">{{item.cantidad}}</label>
              </div>
            </td>

            <!-- <td class='text-xs-left'>{{ calcpvptotallinea(props) | formatPrice | currency }}</td> -->
            <!-- <td class="justify-center text-center">{{ item.pvp }}€</td> -->
            <!-- <td class="justify-center text-center"><div class="tachado" v-if="item.descuentos!=''">{{ item.texto_pvptotallinea }}</div>{{ item.texto_pvptotallineadescuentos }}</td> -->
            <td class="justify-center text-center">
              <v-btn icon="icon" @click="dialogdelete.active = true;dialogdelete.item=item" v-if="prealbaran.estado=='Pendiente'">
                <v-icon class="grey--text">delete</v-icon>
              </v-btn>
            </td>
          </tr>
          <!-- </tbody> -->
        </template>
        <template slot="no-data">
          <v-alert :value="true" color="white">{{ $t("carro_vacio") }}</v-alert>
        </template>
      </v-data-table>

      <!--Tabla version movil -->
      <div
        class="tabla_lineas_movil"
        v-for="item in prealbaran.lineas"
        :key="'movil_' + item.idlinea + '_'"
      >
        <v-chip v-if="item.referencia" label color="#666666" text-color="white" class="ml-0 mr-3 mb-3">
            <v-icon left>label</v-icon>{{item.referencia}}
        </v-chip>
        <label> {{ item.descripcion }}</label>

        <div class="line">
          <div class='flex-content'>
            <label class="prev_movil" > Prevista {{ item.cantinicial }} </label>
            <div class="box-cantidad" disabled>
              <v-icon @click="btnRestCant(item)" style="border-right:none;" v-if="prealbaran.estado=='Pendiente'">remove</v-icon>
              <input v-model="item.cantidad" @change="actualizarlinea(item)" name="" step="0.5" type="number" value="" v-if="prealbaran.estado=='Pendiente'"/>
              <v-icon @click="btnSumCant(item)" style="border-left:none;" v-if="prealbaran.estado=='Pendiente'">add</v-icon>
              <label v-if="prealbaran.estado=='Confirmado'">{{item.cantidad}}</label>
            </div>
          </div>
          <v-btn icon="icon" @click="dialogdelete.active = true;dialogdelete.item=item" v-if="prealbaran.estado=='Pendiente'">
            <v-icon class="grey--text">delete</v-icon>
          </v-btn>
         

            <!-- <v-dialog v-if="confirmer.active" :value="confirmer.active" max-width="290">
              <v-card>
                <v-card-title class="headline">{{
                  confirmer.title
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click.native="borrararticulo(item)"
                    >Confirm</v-btn
                  >
                  <v-btn color="blue darken-1" text @click.native="confirmer.active = false"
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog> -->
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogdelete.active" max-width="490" persistent>
        <v-card>
          <v-card-title class="headline">{{
            dialogdelete.title
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#555555" text @click.native="dialogdelete.active = false">Cancelar</v-btn>
            <v-btn color="#555555" text @click.native="borrararticulo(dialogdelete.item)">borrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 

    
    <div class="crearalbaranfromprealbaran" row>
      <v-flex sm-6>
        
        

        <v-layout shrink justify-end style="margin:10px 0px;display: block;" v-if="prealbaran.estado=='Pendiente'">

           <v-card style="padding:10px 20px 20px 20px;margin-bottom:20px;">
            <v-textarea
              class="uppercase"
              clearable
              v-model="observaciones"
              auto-grow
              rows="2"
              clear-icon="cancel"
              :disabled="prealbaran.estado=='Confirmado'"
              v-bind:label="$t('prealbaran_observaciones')"
            ></v-textarea>
            <button @click="update_observaciones()" class="buttoncreate"><v-icon class='icon' style="">save</v-icon>Guardar</button>
          </v-card>

          <v-card style="padding:10px 20px 20px 20px;">
            <div class="optionsfirma">
              <v-switch v-model="checkboxfirmarprealbaran" :label="`Firmar prealbarán`" hide-details :disabled="checkboxfirmarprealbaran_disabled"></v-switch>
              <v-switch v-model="checkboxenviaremail" :label="`Enviar email`" hide-details :disabled="checkboxenviaremail_disabled"></v-switch>
              <AddEmail v-if="checkboxenviaremail" v-bind:objects="email_list" />
            </div>

            <div v-if="checkboxfirmarprealbaran"><Firma v-bind:text="'FIRMAR Y CREAR ALBARÁN'" /></div>
            <div v-else> <button @click="button_crearalbaran()" class="buttoncreate"><v-icon class='icon' style="">create</v-icon>CREAR ALBARÁN</button></div>

            <div class="prealbaran-info">
              <label v-if="prealbaran.codpresupuestocli"> <b>PRESUPUESTO: </b>SI ({{ prealbaran.codpresupuestocli }})</label>
              <label v-else> <b>PRESUPUESTO: </b>NO</label>
            </div>
          </v-card>
        </v-layout>
      </v-flex>
    </div>
    <v-dialog v-model="dialogVerificacionAlbaran" max-width="400">
        <v-card>
        <v-card-title class="headline">Crear Albarán</v-card-title>
          <v-card-text>
            <div class="mb-1" style="color:#bd0808;" v-if="checkboxenviaremail && email_envio_visible==''"><b>Atención!</b> no se ha especificado <b>email</b>!</div>
            <div class="mb-1" style="color:#bd0808;" v-if="observaciones==''"><b>Atención!</b> no hay <b>observaciones</b>!</div>
            <div class="mb-1" style="color:#bd0808;" v-if="checkboxfirmarprealbaran && firma.nombrefirmante==''"><b>Atención!</b> sin <b>Nombre firmante</b>!</div>
            <div v-if="checkboxenviaremail && email_envio_visible !=''"><b>Enviar a:</b><br> {{email_envio_visible}}</div>
            <div v-if="!checkboxenviaremail && !checkboxfirmarprealbaran">Crear Albarán sin firma y sin enviar por email</div>
          </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#555555" text @click="dialogVerificacionAlbaran=false">Cancelar</v-btn>
            <v-btn color="#555555" text @click="crearalbaranfromprealbaran()">Crear</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
//import CarroLineal from "@/components/CarroLineal";
// import CarroMatricial from "@/components/CarroMatricial";
import { gw } from "@/gw.js";
import Firma from "@/components/Firma";
import AddEmail from "@/components/AddEmail";

export default {
  name: "PreAlbaran",
  mixins: [gw],
  components: {
    Firma,
    AddEmail
  },
  data(vm) {
    return {
      //direcciones:[],
      //cliente: {},
      //articuloscarro: [],
      show_AddEmail:false,
      prealbaran: {},
      tipoarticulos: ['Todos'],
      codtipoinel_selected: 'Todos',
      dialogVerificacionAlbaran: false,
      checkboxfirmarprealbaran: true,
      checkboxfirmarprealbaran_disabled: false,
      checkboxenviaremail: true,
      checkboxenviaremail_disabled: false,
      /*direccion_selected: {
        id:'',
        direccion:''
      },*/
      /* calle_envio: {
        pais:'',
        direccion:''
      }, */
      date: new Date().toISOString().substr(0, 10),
      dateformated: "",
      menu: false,
      //codpedcli: `auto-${new Date().getTime()}`,
      observaciones: "",
      //stocks:[],
      //totales: [],
      headers: [
        {
          text: "Referencia",
          value: "referencia",
          sortable: false
        },
        {
          text: "Descripcion",
          value: "descripcion",
          sortable: false
        },
        {
          text: "Prevista",
          value: "cantinicial",
          sortable: false,
          align: "center"
        },
        {
          text: "Cantidad",
          value: "cantidad",
          sortable: false,
          align: "center"
        },
        { text: 'Actions', value: 'action', sortable: false, align:"center" }      
      ],
      dialog: false,
      articulo_ref: "",
      articulo_desc: "",
      articulo_desc_anterior: "",
      results_articulo: [],
      timeout: null,
      items_actualizarlinea: [],
      dialog_view: "insert",
      prealbaranlinea:{
        cantidad: "1",
        descripcion: "",
        idprealbaran: "",
        referencia: ""
      },
      dialogdelete: {
        active: false,
        title: vm.$i18n.t("prealbaran_dialog_eliminar"),
        item:{}
      },
      email_list: [],
      email_envio_visible: ""
    };
  },
  mounted: function() {
    let _this = this;

    if(this._routerRoot._route.params.estado=="crear"){
      this.dialog = true;
      this.setBreadcrumbs([
              {
                text: "MRP",
                disabled: false,
                to: "/ordenestrabajo"
              },
              {
                text: this._routerRoot._route.params.codigo,
                disabled: false,
                to: "/ordentrabajo/" + this._routerRoot._route.params.codigo
              },
            ]);
    }
    else{
      let post = { codigo: this._routerRoot._route.params.codigo };
      this.api("/peticion/prealbaran", post, true).then(
        _data => {
          if (_data.datos.prealbaran != undefined) {
            _this.prealbaran = _data.datos.prealbaran;
            _this.observaciones = _this.prealbaran.observaciones;
            if(_this.observaciones==null){_this.observaciones="";}

            if(_this.prealbaran.codpresupuestocli!=null && _this.prealbaran.codpresupuestocli!=""){
              _this.checkboxfirmarprealbaran = false;
              _this.checkboxenviaremail= false;
            }

            if(_this.prealbaran.email!="" && _this.prealbaran.email!=null){
              _this.prealbaran.email+=";";
            }

            if(_this.prealbaran.estado!='Pendiente'){
              _this.to("/albaran/"+_this.prealbaran.codigo);
            }

            //deshabilitar firma si
            //mantenimiento, montaje, reparación o tiene presupuesto
            if(_this.prealbaran.tipo == "Mantenimientos" || _this.prealbaran.tipo == "Montajes" || _this.prealbaran.tipo == "Reparaciones" || _this.prealbaran.codpresupuestocli!=null){
              _this.checkboxfirmarprealbaran = false;
              _this.checkboxfirmarprealbaran_disabled = true;
              _this.checkboxenviaremail = false;
              _this.checkboxenviaremail_disabled = true;
            }

            _this.setBreadcrumbs([
              {
                text: "MRP",
                disabled: false,
                to: "/ordenestrabajo"
              },
              {
                text: _this.prealbaran.codorden,
                disabled: false,
                to: "/ordentrabajo/" + _this.prealbaran.codorden
              },
              {
                text: _this.prealbaran.codigo,
                disabled: true,
                to: ""
              }
            ]);

            /*_data.datos.lineas.forEach(art => {
              //Obtenemos las 2 dimensiones (talla y color)
              let dim = art.descripcionref.split('-');
              let lista_stocks = _data.datos.stock;

              let _pvp = 0;
              if(_data.datos.precios && _data.datos.precios[art.b] && _data.datos.precios[art.b].p){_pvp=_data.datos.precios[art.b].p}
              let ped = {
                fotoportada: _this.getfotoarticulos(art.r),
                r: art.r,
                b: art.b,
                d: art.d,
                cant: art.cant == null ? 0 : art.cant,
                d1_value: dim[1],//dimensión 1
                d2_value: dim[0],//dimensión 2
                pvp: _pvp,
                descuentos: "",
                codimp: art.codimpuesto,
                stock: lista_stocks[art.b]
              };
              _this.articuloscarro.push(ped);
            });*/
          }
          else{
            this.setMessage({
              active:true,
              icon:'error',
              coloricon:'#bd0909',
              text:'ERROR. No se encontraron datos o ha habido un error',
              buttons:[{
                text:'volver',
                to:'back'
              }]
            });
          }
        },
        err => {
          console.log("Error no controlado API");
          console.log(err);
        }
      );
    }

    this.api('/peticion/tiposarticulos/gettiposarticulos').then(
    (data) => {
      _this.tipoarticulos = data.datos;
    },
      (err) => {
          console.log("Error no controlado API");
          console.log(err);
    });

  },
  watch: {
    articulo_desc(val) {
      if (val != undefined ) {
          if (val.length >= 3) {
          this.articulo_desc_anterior = val;
          this.closekeyboard();
          var _this = this;
          clearTimeout(this.timeout);
          this.timeout = setTimeout(function() {
            _this.buscararticulo(val);
          }, 500);
        } else if (val.length < 3) {
          this.results_articulo = [];
        }
        this.articulo_desc = val;
      }else{
        thiq.articulo_desc = null;
      }
    },
    checkboxfirmarprealbaran(){
      //Si se quiere firmar obligatoriamente hay que enviar un emeil
      if(this.checkboxfirmarprealbaran){this.checkboxenviaremail = true;}
    },
    checkboxenviaremail(){
      //Si no se envia email no se puede firmar
      if(!this.checkboxenviaremail){this.checkboxfirmarprealbaran = false;}
    },
    firma(){
      this.button_crearalbaran();
      //this.dialogVerificacionAlbaran = true;
      //this.crearalbaranfromprealbaran();
    },
    prealbaran() {
      if (this.prealbaran != null) {
        this.email_list = [
          // ...this.email_list,
          ...(this.prealbaran.emails || []).map(text => ({
            text,
            selected: false
          })),
          ...(this.prealbaran.emailsalbaran || []).map(text => ({
            text,
            selected: true
          }))
        ]
      }
    }
    /*'prealbaranlinea.descripcion': function (val) {
      this.prealbaranlinea.descripcion = val.toUpperCase();
    },*/
    /*observaciones: function (val) {
      this.observaciones = val.toUpperCase();
    },*/
  },
  methods: {
    button_crearalbaran(){
      if(this.checkboxenviaremail){//Enviar el albaran firmado, acabado de crear o desde albará indicando el codigo (mismo codigo en prealbaran y albaran)
        //verificamos email o emails
        var emails = [];

        this.email_list.forEach(e => {
          if(e.selected){
              emails.push(e.text);
          }
          
        });
        if(emails.length==0){
          this.toasted("Debe indicar almenos un Email", "error", "error");
          this.dialogVerificacionAlbaran = false;
          //this.$refs.email.focus();
          return true;
        }
        for (let i = 0; i < emails.length; i++){
          if(emails[i]!="" && !this.validarEmail(emails[i])){
            //Si entramos aquí es que hay algún email que es incorrecto
            this.toasted("Hay un Email incorrecto, deseleccionelo y vuelva a intertarlo", "error", "error");
            this.dialogVerificacionAlbaran = false;
            //this.$refs.email.focus();
            return true;
          }
        }
        this.email_envio_visible = emails.join(', ');
      }
      else{
         this.email_envio_visible = '';
      }

      this.dialogVerificacionAlbaran=true;
    },
    btnRestCant(item) {
      //if (item.cantidad > 1) {
        item.cantidad = parseFloat(item.cantidad) - 0.5;
        this.actualizarlinea_botoncant(item);
      //}
    },
    btnSumCant(item) {
      //if (item.cantidad >= 0) {
        item.cantidad = parseFloat(item.cantidad) + 0.5;
        this.actualizarlinea_botoncant(item);
      //}
    },
    closedialog() {
      if(this._routerRoot._route.params.estado=="crear"){
        this.to("/ordentrabajo/"+this._routerRoot._route.params.codigo);
      }
      else{
        this.dialog = false;
        this.articulo_desc = "";
        this.articulo_desc_anterior = "";
        this.results_articulo = [];
        this.dialog_view = "insert";
        this.prealbaranlinea.cantidad = "1";
        this.prealbaranlinea.descripcion = "";
        this.prealbaranlinea.idprealbaran = "";
        this.prealbaranlinea.referencia = ""; 
      }
    },
    selecionar_articulo(item){
      this.prealbaranlinea.referencia = item.r === undefined ? this.prealbaranlinea.referencia : item.r;
      this.prealbaranlinea.descripcion = item.d === undefined ? this.prealbaranlinea.descripcion.toUpperCase() : item.d.toUpperCase();
      this.prealbaranlinea.cantidad = this.prealbaranlinea.cantidad;
      this.prealbaranlinea.idprealbaran = this.prealbaran.idprealbaran;

      this.articulo_desc = "";
      this.articulo_desc_anterior = "";
      this.results_articulo = [];
      this.dialog_view = "insert";
    },
    borrararticulo(item) {
      item.cantidad = 0;
      this.actualizarlinea(item);
    },
    buscararticulo(search) {
      let _this = this;
      let data = {};
      //if(kind == 'descripcion') data['descripcion'] = search;
      data["busqueda"] = search;
      data["codtipoinel"] = this.codtipoinel_selected;
      this.api("/peticion/articulosfiltro/", data, false).then(
        resp => {
          if (resp.success == 1 && resp.datos != null) {
            //Todo Ok
            _this.results_articulo = resp.datos;
            //console.log(_this.results_articulo);
          }
          else if(resp.success == 2){_this.toasted(resp.msg,"error","error");}

          else{
            _this.results_articulo = "";
          }
        },
        err => {
          console.log("Error no controlado API");
          console.log(err);
          
        }
      );
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    crearalbaranfromprealbaran(item){
      var filter = {};
      var data = []; data[0] = {};

      data[0]["prealbaran"] = false; //Crear prealbarán vacio, Si se envía a true no importa nada más.
      //data[0]["recalculartotales"] = true; //Calcula los totales del prealbaran

      filter["codigo"] = this._routerRoot._route.params.codigo;
      filter["idprealbaran"] = this.prealbaran.idprealbaran;
      filter["codorden"] = this.prealbaran.codorden;
      data[0]["codigo"] = this._routerRoot._route.params.codigo;
      data[0]["idprealbaran"] = this.prealbaran.idprealbaran;
      data[0]["codorden"] = this.prealbaran.codorden;
      if(this.observaciones==null){this.observaciones="";}
      data[0]["observaciones"] = this.observaciones.toUpperCase();

      data[0]["albaranar"] = true; //Crear el albaran a partir del prealbaran
      //data[0]["codoperario"] = this.auth.coduser; 

      if(this.checkboxenviaremail){//Enviar el albaran firmado, acabado de crear o desde albará indicando el codigo (mismo codigo en prealbaran y albaran)
        //verificamos email o emails
        var emails = [];

        this.email_list.forEach(e => {
          if(e.selected){
              emails.push(e.text);
          }
          
        });
        if(emails.length==0){
          this.toasted("Debe indicar almenos un Email", "error", "error");
          this.dialogVerificacionAlbaran = false;
          //this.$refs.email.focus();
          return true;
        }
        for (let i = 0; i < emails.length; i++){
          if(emails[i]!="" && !this.validarEmail(emails[i])){
            //Si entramos aquí es que hay algún email que es incorrecto
            this.toasted("Hay un Email incorrecto, deseleccionelo y vuelva a intertarlo", "error", "error");
            this.dialogVerificacionAlbaran = false;
            //this.$refs.email.focus();
            return true;
          }
        }
        data[0]["email_envio"] = emails.join(';');
      }
      else{
        data[0]["email_envio"] = '';
      }

      if(this.checkboxfirmarprealbaran){
        data[0]["firmadocli"] = true;
        data[0]["firma_dataURL"] = this.firma.dataURL;
        data[0]["firma_nombrefirmante"] = this.firma.nombrefirmante;
      }
      else{
        data[0]["firmadocli"] = false;
        data[0]["firma_dataURL"] = '';
        data[0]["firma_nombrefirmante"] = '';
      }

      let _this = this;
      this.api("/peticion/eneboo/prealbaran", {'prealbaranes':{'filter':filter,'data':data}}, true).then(
        resp => {
          if (resp.success == 1) {//Me devuelve algo, Peeero puede devolver un error..
            //Todo Ok
            _this.setMessage({
              active:true,
              icon:'check_circle',
              coloricon:'#1ca020',
              text:'Se ha creado el Albarán con éxito',
              buttons:[
              {
                text:'Menú principal',
                to:'/'
              },
              {
                text:'Aceptar',
                to:''
              }]
            });
            _this.to('/albaran/'+resp.datos.prealbaranes.data[0].codigo);
          }
          else if(resp.success == 2){_this.toasted(resp.msg,"error","error");}
          else {
            //error
            console.log(resp);
            _this.toasted("No se ha podido crear el albarán", "error", "error");

          }
          // if(resp.success == 1 )  _this.toasted("Se ha " + texto + " al carro corectamente", "success", 'shopping_cart');
          // else _this.toasted("No se ha podido al carro", "error", 'shopping_cart');
        },
        err => {
          _this.toasted("No se ha podido crear el albarán. ERR!", "error", "error");
          console.log("Error no controlado API");
          console.log(err);
        }
      );
    },
    actualizarlinea_botoncant(item){
      let _this = this;
      this.items_actualizarlinea[item.idlinea]=item;
       clearTimeout(this.timeout);
        this.timeout = setTimeout(function(){
          _this.actualizarlinea(_this.items_actualizarlinea);
        }, 800);
    },
    actualizarlinea(item){

      //Si es un prealbaran nuevo hay que crearlo. Otra funcion
      if(this._routerRoot._route.params.estado=="crear"){
        this.crearprealbaran();
        return true;
      }

      var filter = {};
      var data=[]; data[0]={};

      //data[0]["prealbaran"] = true; //Crear prealbarán vacio, Si se envía a true no importa nada más.
      //data[0]["recalculartotales"] = true; //Calcula los totales del prealbaran

      filter["codigo"] = this._routerRoot._route.params.codigo;
      filter["idprealbaran"] = this.prealbaran.idprealbaran;
      filter["codorden"] = this.prealbaran.codorden;
      data[0]["codigo"] = this._routerRoot._route.params.codigo;
      data[0]["idprealbaran"] = this.prealbaran.idprealbaran;
      data[0]["codorden"] = this.prealbaran.codorden;

      //data[0]["albaranar"] = false; //Crear el albaran a partir del prealbaran

      //data[0]["email_envio"] = ''; 

      //data[0]["firmadocli"] = false;
      //data[0]["firma_dataURL"] = '';
      //data[0]["firma_nombrefirmante"] = '';

      //Lineas
      var datalineas = [];
      var i = 0;
      var _item = [];
      if(item['referencia']!=undefined || item['idlinea']!=undefined){
        //Si no es un array hay que convertirlo
        _item[0] = {};
        _item[0]['referencia']=item.referencia;
        _item[0]['cantidad']=item.cantidad;
        _item[0]['idlinea']=item.idlinea;
        _item[0]['descripcion']=item.descripcion.toUpperCase();
      }else{_item=item;}
      _item.forEach(e => {
        datalineas[i] = {};
        datalineas[i]["referencia"] = e.referencia;
        datalineas[i]["cantidad"] = e.cantidad;
        datalineas[i]['idlinea'] = e.idlinea == undefined ? '-1' : e.idlinea;
        datalineas[i]['descripcion'] = e.descripcion.toUpperCase();
        i++;
      });
     

      let _this = this;
      this.api("/peticion/eneboo/lineasprealbaran", {'prealbaranes':{'filter':filter,'data':data},'lineasprealbaranes':{'data':datalineas}}, true).then(
        resp => {
          if (resp.success == 1) {
            //Todo Ok -> Redireccionar al prealbarán creado
            
            var data2 = {};
            data2["idprealbaran"] = this.prealbaran.idprealbaran;
            this.api("/peticion/prealbaran/getlineas", data2, true).then(
              _resp => {     
                if (_resp.success == 1) {     
                  _this.toasted("Se ha actualizado la linea con éxito", "success", 'check');
                  _this.closedialog();
                  _this.dialogdelete.active = false;
                  _this.dialogdelete.item = {};
                  _this.prealbaran.lineas = _resp.datos.lineas;
                  return true;
                }
                else if(resp.success == 2){_this.toasted(resp.msg,"error","error");}
                else{
                  console.log(resp);
                  _this.toasted("No se ha actualizado la linea al prealbarán.", "error", "error");
                }
              },
              err => {
                _this.toasted("No se ha actualizado la linea al prealbarán.", "error", "error");
                console.log("Error no controlado API");
                console.log(err);
                return false;
              }
            );

          }
          else if(resp.success == 2){_this.toasted(resp.msg,"error","error");}
          else {
            //error
            _this.toasted("No se ha actualizado la linea al prealbarán.", "error", "error");
          }
        },
        err => {
          _this.toasted("No se ha actualizado la linea al prealbarán. ERR!", "error", "error");
          console.log("Error no controlado API");
          console.log(err);
        }
      );
    },
    crearprealbaran(){
      //Se creará un prealbaran nuevo cuando se inserte una linea.
      var filter = {};
      var data=[]; data[0]={};

 

      data[0]["prealbaran"] = true; //Crear prealbarán vacio, Si se envía a true no importa nada más.
      //data[0]["recalculartotales"] = true; //Calcula los totales del prealbaran

      //filter["codigo"] = '';
      //filter["idprealbaran"] = '';
      filter["codorden"] = this._routerRoot._route.params.codigo;
      //data[0]["codigo"] = '';
      //data[0]["idprealbaran"] = '';
      data[0]["codorden"] = this._routerRoot._route.params.codigo;

      data[0]["albaranar"] = false; //Crear el albaran a partir del prealbaran
      data[0]["email_envio"] = ''; 
      data[0]["codoperario"] = this.auth.coduser; 


      data[0]["firmadocli"] = false;
      data[0]["firma_dataURL"] = '';
      data[0]["firma_nombrefirmante"] = '';

      //Lineas
      var datalineas = [];datalineas[0] ={};
      datalineas[0]["referencia"] = this.prealbaranlinea.referencia;
      datalineas[0]["descripcion"] = this.prealbaranlinea.descripcion.toUpperCase();
      datalineas[0]["cantidad"] = this.prealbaranlinea.cantidad;

      if(datalineas[0]["referencia"] == "" && datalineas[0]["descripcion"]==""){
        this.toasted("Debes indicar una referencia o una descripción.", "error", "error");
        return true;
      }

      let _this = this;
      this.api("/peticion/eneboo/prealbaran", {'prealbaranes':{'filter':filter,'data':data},'lineasprealbaranes':{'data':datalineas}}, true).then(
        resp => {
          if (resp.success == 1) {
            //Todo Ok -> Redireccionar al prealbarán creado
            _this.toasted("Se ha creado el prealbarán con exito.", "success", 'check');
            //this.dialogcrearprealbaran = false;
            //this.closedialog();
            //this.dialogdelete.active = false;
            //this.dialogdelete.item = {};
            _this.to('/prealbaran/ver/'+resp.datos.prealbaranes.data[0].codigo, "rerender");
          } 
          else if(resp.success == 2){_this.toasted(resp.msg,"error","error");}
          else {
            //error
            console.log(resp);
            _this.toasted("No se ha podido crear el prealbaran", "error", "error");
          }
          // if(resp.success == 1 )  _this.toasted("Se ha " + texto + " al carro corectamente", "success", 'shopping_cart');
          // else _this.toasted("No se ha podido al carro", "error", 'shopping_cart');
        },
        err => {
          _this.toasted("No se ha podido crear el prealbaran. ERR!", "error", "error");
          console.log("Error no controlado API");
          console.log(err);
        }
      );
    },

    update_observaciones(){
      if(this.observaciones==null){this.observaciones="";}
      let post = { codigo: this._routerRoot._route.params.codigo, observaciones:this.observaciones.toUpperCase()};
      let _this = this;

      this.api("/peticion/prealbaran/update_observaciones", post, false).then(
        resp => {
          if (resp.success == 1){
            _this.toasted("Observaciones guardadas correctamente.", "success", 'check');
            _this.prealbaran.observaciones = _this.observaciones.toUpperCase();
          }
          else if(resp.success == 2){_this.toasted(resp.msg,"error","error");}
          else{
            _this.toasted("No se han podido guardar las observaciones.", "error", 'error');
          }
        },
        err => {
          //this.vista = "pedidoerror";
          console.log(err);
        }
      );
    }

    /*recalculartotalesprealbaran(){
      var filter = {};
      var data = []; data[0] = {};

      data[0]["prealbaran"] = false; //Crear prealbarán vacio, Si se envía a true no importa nada más.
      data[0]["recalculartotales"] = true; //Calcula los totales del prealbaran

      filter["codigo"] = this._routerRoot._route.params.codigo;
      filter["idprealbaran"] = this.prealbaran.idprealbaran;
      filter["codorden"] = this.prealbaran.codorden;
      data[0]["codigo"] = this._routerRoot._route.params.codigo;
      data[0]["idprealbaran"] = this.prealbaran.idprealbaran;
      data[0]["codorden"] = this.prealbaran.codorden;

      data[0]["albaranar"] = false; //Crear el albaran a partir del prealbaran

      data[0]["email_envio"] = '';

      data[0]["firmadocli"] = false;
      data[0]["firma_dataURL"] = '';
      data[0]["firma_nombrefirmante"] = '';

      this.api("/peticion/eneboo/prealbaran", {'prealbaranes':{'filter':filter,'data':data}}, true).then(
        resp => {
          if (resp.success == 1) {
            //Todo Ok
            this.toasted("Se ha actualizado la linea con éxito", "success", 'check');

          } else {
            //error
            this.toasted("No se ha actualizado la linea al prealbarán", "error", "error");

          }
          // if(resp.success == 1 )  _this.toasted("Se ha " + texto + " al carro corectamente", "success", 'shopping_cart');
          // else _this.toasted("No se ha podido al carro", "error", 'shopping_cart');
        },
        err => {
          this.toasted("No se ha actualizado la linea al prealbarán. ERR!", "error", "error");
          console.log(err);
        }
      );
    }*/

  }
};
</script>
